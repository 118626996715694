import ioSelectorTemplate from './ioselector.html';
import { IO } from '../../utils/ioselector.enum';
import { UNITS } from '../../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .directive('ioselector', [
      function () {
        return {
          restrict: 'E',
          template: ioSelectorTemplate,
          scope: {
            input: '=',
            type: '=',
            io: '@',
            label: '@',
            form: '@',
            vgForm: '=',
            asLabel: '@',
            outputType: '=', // to identify which output is
            elementType: '=', // to identify which view the component is in
            element: '=',
            header: '=',
          },
          link: function (scope, elem, attrs) {
            attrs.$set('id', () => {
              return `io-selector-${Math.trunc(Math.random() * 1000000).toString()}`;
            });
          },
          controller: [
            '$scope',
            '$element',
            'ioFactory',
            'resFactory',
            '$filter',
            function ($scope, $element, ioFactory, resFactory, $filter) {
              //$scope.output_in = $scope.input;

              // let options, temp, finalCode;
              let options, finalCode;
              $scope.IO_ENUM = IO;
              $scope.updateOptions = prepareCombos;
              $scope.updateOptions45 = updateOptions45;
              $scope.updateOptionsBIT = updateOptionsBIT;
              $scope.getOptionText = getOptionText;
              $scope.updateOptionText = updateOptionText;
              $scope.updateFromInput = updateFromInput;
              $scope.setIOSelectorFromInput = setIOSelectorFromInput;
              $scope.cancel = cancel;
              $scope.init = init;
              $scope.showVeggaModal = showVeggaModal;
              $scope.resetAlert = resetAlert;
              $scope.oldDataFromModal = {};
              $scope.UNITS = UNITS;

              $scope.destroy$ = new Subject();
              $scope.ioFacade = getContainer().resolve('ioFacade');

              $scope.focusedIOSelectorInput = false;

              $scope.isDuplicatedOutputs = false;
              $scope.onlyNumbers = false;
              $scope.validCodeLength = false;
              $scope.codeLength = 0;

              function init() {
                if ($scope.type === UNITS.A_4500) {
                  addedDuplicatedOutputValidation();
                }
                const io = resFactory.inOutCode($scope.type);
                const options = [];

                if (io.options) {
                  Object.keys(io.options).forEach((key) => {
                    options[key] = { ...io.options[key] };
                  });
                }

                io.options = options;

                switch ($scope.io) {
                  case $scope.IO_ENUM.DIGITAL_IN:
                  case $scope.IO_ENUM.DIGITAL_OUT:
                  case $scope.IO_ENUM.ANALOG_IN:
                  case $scope.IO_ENUM.ANALOG_OUT:
                    $scope.codeLength = 8;
                    decodeInput8(io);
                    break;
                  case $scope.IO_ENUM.DIGITAL_IN_5:
                  case $scope.IO_ENUM.DIGITAL_OUT_5:
                  case $scope.IO_ENUM.ANALOG_IN_5:
                    $scope.codeLength = 5;
                    decodeDigital5(io);
                    break;
                }
              }

              function addedDuplicatedOutputValidation() {
                if (isAnalogOrDigitalInput()) {
                  return;
                }

                if ($scope.input === undefined || $scope.input === null || !isConfiguredOutput()) {
                  return;
                }
                const currentOutput = {
                  elementId: $element[0].id,
                  header: +$scope.header,
                  output: $scope.input,
                  outputType: $scope.outputType,
                  elementType: $scope.elementType,
                  element: $scope.element,
                };

                $scope.ioFacade.initializeStagingOutputs(currentOutput);

                $scope.ioFacade.stagingOutputsResponse.value$
                  .pipe(
                    filter((items) => items.find((item) => item.elementId === $element[0].id)),
                    takeUntil($scope.destroy$)
                  )
                  .subscribe((stagingOutputs) => {
                    const element = stagingOutputs.find((stagingOutput) => stagingOutput.elementId === $element[0].id);
                    $scope.isDuplicatedOutputs = element.multipleRegistryOutput;

                    if ($scope.isDuplicatedOutputs) {
                      if ($scope.vgForm) {
                        $scope.vgForm.$setPristine();
                      }
                      return;
                    }

                    setOutputToInput();
                  });
              }

              function getTypes() {
                if ($scope.type === $scope.UNITS.A_2500) {
                  //options.options =  _.filter(options.options, o => { return o.text.toLowerCase().indexOf("virtual") == -1})
                  //Si treiem VIRTUAL de la llista amb un filter, els index de options.options canvien i AgroBee-L passa a ser 2, i per aixo no es veuen i tampoc es configuren bé.
                  const arrayAux = [];
                  arrayAux[0] = options.options[0];
                  arrayAux[1] = options.options[1];
                  arrayAux[3] = options.options[3];
                  return arrayAux;
                } else if ($scope.type === UNITS.A_4500) {
                  let modifResult = JSON.parse(JSON.stringify(options.options));
                  switch ($scope.io) {
                    case $scope.IO_ENUM.DIGITAL_IN:
                      delete modifResult[5];
                      break;
                    case $scope.IO_ENUM.DIGITAL_OUT:
                      delete modifResult[2];
                      delete modifResult[5];
                      break;
                    case $scope.IO_ENUM.ANALOG_IN:
                      modifResult = options.options;
                      break;
                    case $scope.IO_ENUM.ANALOG_OUT:
                      delete modifResult[2];
                      delete modifResult[3];
                      delete modifResult[5];
                      delete modifResult[7];
                      delete modifResult[10];
                      break;
                  }
                  return modifResult;
                } else {
                  return options.options;
                }
              }

              function decodeDigital5(io) {
                options = io;

                switch ($scope.io) {
                  case $scope.IO_ENUM.DIGITAL_IN_5:
                    $scope.types = options.options;
                    $scope.selected = ioFactory.decodeDigitalInput5($scope.input, $scope.type, io);
                    break;
                  case $scope.IO_ENUM.DIGITAL_OUT_5:
                    $scope.types = getTypes();
                    $scope.selected = ioFactory.decodeDigitalOutput5($scope.input, $scope.type);
                    break;
                  case $scope.IO_ENUM.ANALOG_IN_5:
                    $scope.types = options.options;
                    $scope.selected = ioFactory.decodeAnalogInput5($scope.input, $scope.type, io);
                    break;
                }

                // temp = $scope.selected;
                $scope.boxes = getBoxes($scope.selected.type);
                $scope.modules = getModules($scope.selected.type);
                $scope.exits = getExits($scope.selected.type);
                $scope.optionText = getOptionText();
                getCodeText5();
              }

              function getCodeText5() {
                let e1, e2, e3, e4;

                e1 = Number($scope.selected.type) ?? 0;
                e2 = '';
                e3 = Number($scope.selected.module) ?? 0;
                e4 = Number($scope.selected.number) ?? 0;

                e1 = '0' + e1;
                e3 = '00' + e3;
                e4 = '00' + e4;
                e1 = e1.slice(e1.length - 1, e1.length);
                e3 = e3.slice(e3.length - 2, e3.length);
                e4 = e4.slice(e4.length - 2, e4.length);

                $scope.codeText = e1 + e2 + e3 + e4;

                switch ($scope.io) {
                  case $scope.IO_ENUM.DIGITAL_IN_5:
                    finalCode = ioFactory.encodeDigitalInput5($scope.codeText, $scope.type, options);
                    break;
                  case $scope.IO_ENUM.DIGITAL_OUT_5:
                    finalCode = ioFactory.encodeDigitalOutput5($scope.codeText, $scope.type, options);
                    break;
                  case $scope.IO_ENUM.ANALOG_IN_5:
                    finalCode = ioFactory.encodeAnalogInput5($scope.codeText, $scope.type, options);
                    $scope.format = { type: e1, module: e3, number: e4 };
                    break;
                }

                if ($scope.input < 0) {
                  $scope.codeText = '';
                }
              }

              function decodeInput8(io) {
                options = io;
                $scope.types = getTypes();

                if ($scope.type === UNITS.A_4000 || $scope.type === UNITS.A_7000 || $scope.type === UNITS.A_4500) {
                  // 8digits
                  if (
                    ($scope.input === 0 || $scope.input == null || $scope.input == undefined) &&
                    $scope.type === UNITS.A_4500
                  ) {
                    $scope.selected = {
                      type: -1,
                      box: 0,
                      module: 0,
                      number: 0,
                    };
                  } else {
                    switch ($scope.io) {
                      case $scope.IO_ENUM.DIGITAL_IN:
                        $scope.selected = ioFactory.decodeInput8($scope.input, $scope.type, io);
                        break;
                      case $scope.IO_ENUM.DIGITAL_OUT:
                        $scope.selected = ioFactory.decodeOutput8($scope.input, $scope.type, io);
                        break;
                      case $scope.IO_ENUM.ANALOG_IN:
                        $scope.selected = ioFactory.decodeInputA8($scope.input, $scope.type, io);
                        break;
                      case $scope.IO_ENUM.ANALOG_OUT:
                        $scope.selected = ioFactory.decodeOutputA8($scope.input, $scope.type, io);
                        break;
                    }
                  }

                  // temp = $scope.selected;

                  $scope.boxes = getBoxes($scope.selected.type);
                  $scope.modules = getModules($scope.selected.type);
                  $scope.exits = getExits($scope.selected.type);
                  $scope.optionText = getOptionText();

                  getCodeText($scope.io);
                }
              }

              function getCodeText(io) {
                //DigitalInput
                let e1, e2, e3, e4;
                if ($scope.selected.type === -1) {
                  e1 = e2 = e3 = e4 = 0;
                } else {
                  e1 = $scope.selected.type | 0;
                  e2 = $scope.selected.box | 0;
                  e3 = $scope.selected.module | 0;
                  e4 = $scope.selected.number | 0;
                }

                if (io !== $scope.IO_ENUM.ANALOG_IN && e1 === 0) {
                  e1 = '00' + e1;
                  e4 = '00000' + e4;
                  e1 = e1.slice(e1.length - 2, e1.length);
                  e4 = e4.slice(e4.length - 5, e4.length);

                  $scope.codeText = e1 + e2 + e4;
                } else {
                  e1 = '00' + e1;
                  e3 = '000' + e3;
                  e4 = '00' + e4;
                  e1 = e1.slice(e1.length - 2, e1.length);
                  e3 = e3.slice(e3.length - 3, e3.length);
                  e4 = e4.slice(e4.length - 2, e4.length);

                  $scope.codeText = e1 + e2 + e3 + e4;
                }

                switch ($scope.io) {
                  case $scope.IO_ENUM.DIGITAL_IN:
                    finalCode = ioFactory.encodeDigitalInput8($scope.codeText, $scope.type, options);
                    break;
                  case $scope.IO_ENUM.DIGITAL_OUT:
                    finalCode = ioFactory.encodeDigitalOutput8($scope.codeText, $scope.type, options);
                    break;
                  case $scope.IO_ENUM.ANALOG_IN:
                    finalCode = ioFactory.encodeInputA8($scope.codeText, $scope.type, options);
                    break;
                  case $scope.IO_ENUM.ANALOG_OUT:
                    finalCode = ioFactory.encodeOutputA8($scope.codeText, $scope.type, options);
                    break;
                }

                if (finalCode === undefined) {
                  finalCode = 0;
                  $scope.input = 0;
                }
                if ($scope.input < 0 || $scope.input === undefined) {
                  $scope.codeText = '';
                }
              }

              function setIOSelectorFromInput() {
                $scope.focusedIOSelectorInput = true;
              }

              function setNoConfigValue() {
                $scope.optionText = '-';
                // $scope.selected.type = 0;
                // $scope.selected.box = 0;
                // $scope.selected.module = 0;
                // $scope.selected.number = 0;
              }

              function updateFromInput() {
                const reg = /^\d+$/;
                $scope.codeText = event.detail.target.value;
                $scope.onlyNumbers = false;
                $scope.validCodeLength = false;
                if (!reg.test($scope.codeText)) {
                  $scope.onlyNumbers = true;
                  setNoConfigValue();
                  if ($scope.vgForm) {
                    $scope.vgForm.$setPristine();
                  }
                  return;
                }

                if ($scope.codeText.length !== $scope.codeLength) {
                  $scope.onlyNumbers = false;
                  $scope.validCodeLength = true;
                  setNoConfigValue();
                  if ($scope.vgForm) {
                    $scope.vgForm.$setPristine();
                  }
                  return;
                }

                switch ($scope.io) {
                  case $scope.IO_ENUM.DIGITAL_IN:
                  case $scope.IO_ENUM.DIGITAL_OUT:
                  case $scope.IO_ENUM.ANALOG_IN:
                  case $scope.IO_ENUM.ANALOG_OUT:
                    if ($scope.codeText.length === $scope.codeLength) {
                      $scope.selected.type = Number($scope.codeText[0] + $scope.codeText[1]);
                      prepareCombos($scope.selected.type);
                      $scope.selected.box = Number($scope.codeText[2]);
                      $scope.selected.module = Number($scope.codeText[3] + $scope.codeText[4] + $scope.codeText[5]);
                      $scope.selected.number = Number($scope.codeText[6] + Number($scope.codeText[7]));
                    } else {
                      $scope.selected.type = 0;
                      $scope.selected.box = 0;
                      $scope.selected.module = 0;
                      $scope.selected.number = 0;
                    }
                    break;
                  case $scope.IO_ENUM.DIGITAL_IN_5:
                  case $scope.IO_ENUM.DIGITAL_OUT_5:
                  case $scope.IO_ENUM.ANALOG_IN_5:
                  case $scope.IO_ENUM.ANALOG_OUT_5:
                    if ($scope.codeText.length === $scope.codeLength) {
                      $scope.selected.type = Number($scope.codeText[0]);
                      prepareCombos($scope.selected.type);
                      // $scope.selected.box = Number($scope.codeText[2]);
                      $scope.selected.module = Number($scope.codeText[1] + $scope.codeText[2]);
                      $scope.selected.number = Number($scope.codeText[3] + $scope.codeText[4]);
                    } else {
                      $scope.selected.type = 0;
                      $scope.selected.box = 0;
                      $scope.selected.module = 0;
                      $scope.selected.number = 0;
                    }
                    break;
                }
                $scope.optionText = getOptionText();
                generateCodeText($scope.io);

                $scope.updateFromDirective = true;
                if ($scope.type === UNITS.A_4500) {
                  addedToStagingOutput();
                  if (isAnalogOrDigitalInput() || !isConfiguredOutput()) {
                    setOutputToInput();
                  }
                } else {
                  $scope.input = finalCode;
                  if ($scope.vgForm) {
                    $scope.vgForm.$setDirty();
                  }
                  if ($scope.input === undefined || $scope.input === null) {
                    $scope.input = -1;
                    $scope.selected.type = -1;
                    $scope.optionText = '-';
                    $scope.codeText = '00000000';
                  }
                }
              }

              function addedToStagingOutput() {
                if (
                  $scope.io === IO.DIGITAL_IN ||
                  $scope.io === IO.DIGITAL_IN_5 ||
                  $scope.io === IO.ANALOG_IN ||
                  $scope.io === IO.ANALOG_IN_5
                ) {
                  return;
                }
                const currentOutput = {
                  elementId: $element[0].id,
                  header: +$scope.header,
                  output: finalCode,
                  outputType: $scope.outputType,
                  elementType: $scope.elementType,
                  element: $scope.element ?? 1,
                };
                $scope.ioFacade.updateStagingOutputs(currentOutput);
              }

              function generateCodeText(io) {
                switch (io) {
                  case $scope.IO_ENUM.DIGITAL_IN:
                  case $scope.IO_ENUM.DIGITAL_OUT:
                  case $scope.IO_ENUM.ANALOG_IN:
                  case $scope.IO_ENUM.ANALOG_OUT:
                    getCodeText(io);
                    break;
                  case $scope.IO_ENUM.DIGITAL_OUT_5:
                  case $scope.IO_ENUM.DIGITAL_IN_5:
                  case $scope.IO_ENUM.ANALOG_IN_5:
                    getCodeText5();
                    break;
                }
              }

              function updateOptionText() {
                $scope.onlyNumbers = false;
                $scope.validCodeLength = false;
                $scope.optionText = getOptionText();
                generateCodeText($scope.io);

                $scope.updateFromDirective = true;
                if ($scope.type === UNITS.A_4500) {
                  addedToStagingOutput();
                  if (
                    JSON.stringify($scope.selected) === JSON.stringify($scope.oldDataFromModal) &&
                    !$scope.isDuplicatedOutputs
                  ) {
                    $scope.codeText = '';
                    generateCodeText($scope.io);
                    $scope.showModal = false;
                  }

                  if (isAnalogOrDigitalInput() || !isConfiguredOutput()) {
                    setOutputToInput();
                  }
                } else {
                  $scope.input = finalCode;

                  if ($scope.vgForm) {
                    $scope.vgForm.$setDirty();
                  }

                  $scope.showModal = false;
                }
              }

              function cancel() {
                // $scope.selected = temp;
                $scope.showModal = false;
                $scope.selected = $scope.oldDataFromModal;

                if (!$scope.onlyNumbers && !$scope.validCodeLength) {
                  $scope.optionText = getOptionText();
                  getCodeText($scope.io);
                  addedToStagingOutput();
                }

                if ($scope.type === UNITS.A_BIT) {
                  let codeText;
                  const { type, module, number } = $scope.selected;

                  const moduleText = module > 9 ? module : `0${module}`;
                  const numberText = number > 9 ? number : `0${number}`;

                  switch ($scope.io) {
                    case $scope.IO_ENUM.DIGITAL_IN_5:
                      codeText = `${type}${moduleText}${numberText}`;
                      break;
                    case $scope.IO_ENUM.DIGITAL_OUT_5:
                      codeText = `${type}${moduleText}${numberText}`;
                      break;
                    case $scope.IO_ENUM.ANALOG_IN_5:
                      codeText = `${type}${moduleText}${numberText}`;
                      break;
                  }
                  $scope.codeText = codeText;
                }
              }

              function prepareCombos(option) {
                if (option === 2 && $scope.io === $scope.IO_ENUM.ANALOG_IN_5) {
                  $scope.boxes = 0;
                  $scope.modules = 0;
                  $scope.exits = 1;
                } else if (option !== -1) {
                  //Option is not "Ninguno"
                  $scope.boxes = getBoxes(option);
                  $scope.modules = getModules(option);
                  $scope.exits = getExits(option);
                }
                $scope.selected.box = 0;
                $scope.selected.module = 0;
                $scope.selected.number = 0;

                //TODO implement "Ninguno" option, neded to unconfig i/o.

                if ($scope.type === UNITS.A_4500) {
                  $scope.isDuplicatedOutputs = $scope.showModal ? false : $scope.isDuplicatedOutputs;
                  //Default dropdown selected items for A4500
                  switch (option) {
                    case 0: //BASE
                    case 5: //DAVIS
                    case 2:
                      $scope.selected.box = 0;
                      $scope.selected.module = 0;
                      $scope.selected.number = 1;
                      break;
                    case 6: //MB
                    case 11: //"EXP BASE"
                      $scope.selected.box = 0;
                      $scope.selected.module = 1;
                      $scope.selected.number = 1;
                      break;
                    case 3: //AGROBEEL
                    case 7: //AM120
                    case 10: //AR433
                      $scope.selected.box = 1;
                      $scope.selected.module = 1;
                      $scope.selected.number = 1;
                      break;
                    default:
                      $scope.selected.box = 0;
                      $scope.selected.module = 0;
                      $scope.selected.number = 0;
                  }
                }
              }

              function getBoxes(num) {
                if (options && num >= 0) {
                  //Prevent "Ninguno" option
                  const model = options.options[num];
                  const arrayResponse = [];
                  if (model?.brange > 0) {
                    for (let index = 0; index < model.brange; index++) {
                      arrayResponse[index + 1] = { text: model.box + ' ' + (index + 1) };
                    }
                  }
                  return arrayResponse;
                }
              }

              function getModules(num) {
                if (options && !isNaN(num) && num >= 0) {
                  //Prevent "Ninguno" option
                  const model = options.options[num];
                  let arrayResponse = [];

                  if ($scope.io === $scope.IO_ENUM.ANALOG_IN && $scope.type === UNITS.A_4500) {
                    switch (num) {
                      case 0: //BASE
                        arrayResponse[0] = { text: $filter('translate')('io.io38') };
                        arrayResponse[1] = { text: $filter('translate')('io.io39') };
                        break;
                      default:
                        if (model?.mrange > 0) {
                          for (let index = 0; index < model.mrange; index++) {
                            arrayResponse[index + 1] = { text: model.module + ' ' + (index + 1) };
                          }
                        }
                        break;
                    }
                  } else if ($scope.io === $scope.IO_ENUM.ANALOG_OUT && $scope.type === UNITS.A_4500) {
                    switch (num) {
                      case 0: //BASE
                        $scope.selected.module = 0;
                        arrayResponse = [];
                        break;
                      case 6: //MB
                      case 11: //EXPB
                        if (model?.mrange > 0) {
                          for (let index = 0; index < model.mrange; index++) {
                            arrayResponse[index + 1] = { text: model.module + ' ' + (index + 1) };
                          }
                        }
                        break;
                    }
                  } else {
                    if (model?.mrange > 0) {
                      for (let index = 0; index < model.mrange; index++) {
                        arrayResponse[index + 1] = { text: model.module + ' ' + (index + 1) };
                      }
                    }
                  }
                  return arrayResponse;
                }
              }

              function getExits(num) {
                if (options && !isNaN(num) && num >= 0) {
                  //Prevent "Ninguno" option
                  let model = options.options[+num];
                  let arrayResponse = [];
                  //Ens permet tenir un range de sortides diferent en funció si estem amb entrada o sortida
                  //Actualment només tenim la diferencia I/O de rangs al 4500, es pot millorar a la resta.
                  if ($scope.type === UNITS.A_4500) {
                    switch ($scope.io) {
                      case $scope.IO_ENUM.DIGITAL_IN:
                        if (model?.nrangeIn > 0) {
                          for (let index = 0; index < model.nrangeIn; index++) {
                            arrayResponse[index + 1] = { text: index + 1 };
                          }
                        }
                        break;
                      case $scope.IO_ENUM.ANALOG_IN:
                        switch (+num) {
                          case 0: //BASE
                            if ($scope.selected.module == 0) {
                              //BASE
                              for (let index = 0; index < 16; index++) {
                                arrayResponse[index + 1] = { text: index + 1 };
                              }
                            } else if ($scope.selected.module === 1) {
                              //Sensores especificos
                              arrayResponse[1] = { text: $filter('translate')('sensors.s11') };
                              arrayResponse[2] = { text: $filter('translate')('io.io1') };
                            }
                            break;
                          case 2: //Virtual
                            arrayResponse[1] = { text: 1 };
                            break;
                          case 3: //AGROBEEL
                            for (let index = 0; index < 13; index++) {
                              arrayResponse[index + 1] = { text: index + 1 };
                            }

                            arrayResponse[14] = { text: $filter('translate')('io.io2') };
                            arrayResponse[15] = { text: $filter('translate')('io.io3') };
                            arrayResponse[16] = { text: $filter('translate')('io.io4') };

                            break;
                          case 5: //DAVIS
                            arrayResponse[1] = { text: $filter('translate')('io.io5') };
                            arrayResponse[2] = { text: $filter('translate')('io.io6') };
                            arrayResponse[3] = { text: $filter('translate')('io.io7') };
                            arrayResponse[4] = { text: $filter('translate')('io.io8') };
                            arrayResponse[5] = { text: $filter('translate')('io.io9') };
                            arrayResponse[6] = { text: $filter('translate')('io.io10') };
                            arrayResponse[7] = { text: $filter('translate')('io.io11') };
                            arrayResponse[8] = { text: $filter('translate')('io.io12') };
                            arrayResponse[9] = { text: $filter('translate')('io.io13') };
                            arrayResponse[10] = { text: $filter('translate')('io.io14') };
                            arrayResponse[11] = { text: $filter('translate')('io.io15') };
                            arrayResponse[12] = { text: $filter('translate')('io.io16') };
                            arrayResponse[13] = { text: $filter('translate')('io.io17') };
                            arrayResponse[14] = { text: $filter('translate')('io.io18') };
                            arrayResponse[15] = { text: $filter('translate')('io.io19') };
                            arrayResponse[16] = { text: $filter('translate')('io.io20') };
                            arrayResponse[17] = { text: $filter('translate')('io.io21') };
                            arrayResponse[18] = { text: $filter('translate')('io.io22') };
                            arrayResponse[19] = { text: $filter('translate')('io.io23') };
                            arrayResponse[20] = { text: $filter('translate')('io.io24') };
                            arrayResponse[21] = { text: $filter('translate')('io.io25') };
                            arrayResponse[22] = { text: $filter('translate')('io.io26') };
                            arrayResponse[23] = { text: $filter('translate')('io.io27') };
                            arrayResponse[24] = { text: $filter('translate')('io.io28') };
                            arrayResponse[25] = { text: $filter('translate')('io.io29') };
                            arrayResponse[26] = { text: $filter('translate')('io.io30') };
                            arrayResponse[27] = { text: $filter('translate')('io.io31') };
                            arrayResponse[28] = { text: $filter('translate')('io.io32') };
                            arrayResponse[29] = { text: $filter('translate')('io.io33') };
                            arrayResponse[30] = { text: $filter('translate')('io.io34') };
                            arrayResponse[31] = { text: $filter('translate')('io.io35') };
                            arrayResponse[32] = { text: $filter('translate')('io.io36') };
                            arrayResponse[33] = { text: $filter('translate')('io.io37') };

                            break;
                          case 6: //MB
                          case 11: //Expansion Base
                            if (model?.nrangeIn > 0) {
                              for (let index = 0; index < model.nrangeIn; index++) {
                                arrayResponse[index + 1] = { text: index + 1 };
                              }
                            }

                            break;
                          case 7: //AM120
                          case 10: //AR433
                            if (model?.brange > 0) {
                              for (let index = 0; index < model.brange; index++) {
                                arrayResponse[index + 1] = { text: index + 1 };
                              }
                            }
                            break;
                        }
                        break;

                      case $scope.IO_ENUM.ANALOG_OUT:
                        switch (+num) {
                          case 0: //BASE
                            if ($scope.selected.module === 0) {
                              //BASE
                              if (model?.nrangeOut > 0) {
                                for (let index = 0; index < model.nrangeOut; index++) {
                                  arrayResponse[index + 1] = { text: index + 1 };
                                }
                              }
                            }
                            break;
                          case 11: //Expansion Base
                            _.times(10, function (k) {
                              //TODO Utilitzar variable global
                              arrayResponse[k + 1] = { text: Number(k + 1) };
                            });
                            break;
                          default:
                            if (model?.nrange > 0) {
                              for (let index = 0; index < model.nrange; index++) {
                                arrayResponse[index + 1] = { text: index + 1 };
                              }
                            }
                            break;
                        }
                        break;

                      default:
                        if (model?.nrange > 0) {
                          for (let index = 0; index < model.nrange; index++) {
                            arrayResponse[index + 1] = { text: index + 1 };
                          }
                        }
                        break;
                    }
                  } else {
                    // This case is because BIT agrobee-l allows 9 inputs, but when analog in allows 16 outs
                    if ($scope.type === UNITS.A_BIT && $scope.selected.type === 3) {
                      model.nrange = $scope.io === $scope.IO_ENUM.ANALOG_IN_5 ? 16 : 9;
                    }
                    if (model?.nrange > 0) {
                      for (let index = 0; index < model.nrange; index++) {
                        arrayResponse[index + 1] = { text: index + 1 };
                      }
                    }
                  }

                  return arrayResponse;
                }
              }

              function getOptionText() {
                if ($scope.selected && $scope.selected.type !== -1) {
                  let response = '';

                  response = response + ($scope.types[$scope.selected.type].text || ' ');

                  if ($scope.selected.box !== undefined && $scope.boxes !== null && $scope.boxes[$scope.selected.box]) {
                    response = response + ' - ' + ($scope.boxes[$scope.selected.box].text || ' ');
                  }
                  if ($scope.selected.module !== undefined && $scope.modules[$scope.selected.module]) {
                    response = response + ' - ' + ($scope.modules[$scope.selected.module].text || ' ');
                  }
                  if ($scope.selected.number !== undefined && $scope.exits[$scope.selected.number]) {
                    response = response + ' - ' + ($scope.exits[$scope.selected.number].text || ' ');
                  }

                  return response;
                } else {
                  return '-';
                }
              }

              function showVeggaModal($event) {
                if ($event) {
                  $scope.oldDataFromModal = {
                    type: $scope.selected.type,
                    box: $scope.selected.box,
                    module: $scope.selected.module,
                    number: $scope.selected.number,
                  };

                  $scope.boxes = getBoxes($scope.selected.type);
                  $scope.modules = getModules($scope.selected.type);
                  $scope.exits = getExits($scope.selected.type);

                  $scope.showModal = true;
                }
              }

              function updateOptions45(option) {
                if ($scope.type === UNITS.A_4500 && $scope.io === 'analogin') {
                  $scope.isDuplicatedOutputs = $scope.showModal ? false : $scope.isDuplicatedOutputs;
                  $scope.exits = getExits(option);
                  $scope.selected.number = 0;
                }
              }

              function updateOptionsBIT() {
                // Since A_BIT can have sensor alimentation (BASE type) in digital outs as exit 00101, user must be able to select module 1 and exit 1.
                // If module === 0, BIT has 26 available exits
                if ($scope.io !== 'digitalout5') return;
                let exits = [];

                if ($scope.type === UNITS.A_BIT && $scope.selected.type === 0) {
                  if ($scope.selected.module === 1) {
                    $scope.selected.number = 1;
                    $scope.exits = [{ text: 1 }];
                  } else {
                    for (let index = 0; index < options.options[0].nrange; index++) {
                      exits[index + 1] = { text: index + 1 };
                    }
                    $scope.exits = exits;
                  }
                }
                return exits;
              }

              function isAnalogOrDigitalInput() {
                return (
                  $scope.io === IO.DIGITAL_IN ||
                  $scope.io === IO.DIGITAL_IN_5 ||
                  $scope.io === IO.ANALOG_IN ||
                  $scope.io === IO.ANALOG_IN_5
                );
              }

              function isConfiguredOutput() {
                return $scope.outputType && $scope.elementType;
              }

              function setOutputToInput() {
                $scope.input = finalCode;

                if ($scope.input === undefined || $scope.input === null) {
                  $scope.input = -1;
                  $scope.selected.type = -1;
                  $scope.optionText = '-';
                  $scope.codeText = '00000000';
                }

                if (($scope.vgForm && $scope.showModal) || ($scope.vgForm && $scope.focusedIOSelectorInput)) {
                  $scope.vgForm.$setDirty();
                }

                if ($scope.showModal) {
                  $scope.showModal = false;
                }

                if ($scope.focusedIOSelectorInput) {
                  $scope.focusedIOSelectorInput = false;
                }
              }

              function resetAlert() {
                if ($scope.type === UNITS.A_4500) {
                  $scope.isDuplicatedOutputs = false;
                }
              }

              $scope.$watch('input', () => {
                if (!$scope.updateFromDirective) {
                  init();
                }
                $scope.updateFromDirective = false;
              });

              // $scope.$on('VeggaInputChange', (_e, data) => {
              //   updateFromInput();
              // });

              $scope.$on('$destroy', function () {
                $scope.destroy$.next();
                $scope.destroy$.complete();
              });
            },
          ],
        };
      },
    ]);
})();
