import { getContainer } from '@vegga/front-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('newmapsController', newmapsController);

  newmapsController.$inject = ['$rootScope', '$scope', '$state', 'mapsFactory', '$filter', '$timeout', '$confirm'];

  function newmapsController($rootScope, $scope, $state, mapsFactory, $filter, $timeout, $confirm) {
    var vm = $scope.$parent.vm;
    var maps;
    vm.id;
    vm.mapsFacade = getContainer().resolve('mapsFacade');
    vm.destroyMaps$ = new Subject();

    activate();

    /*******************/

    function activate() {
      vm.editMap = editMap;
      vm.newMap = newMap;
      vm.selectMap = selectMap;
      vm.deleteMap = deleteMap;
      vm.loadUserMaps = loadUserMaps;
      vm.carouselNext = carouselNext;
      vm.carouselPrev = carouselPrev;
      vm.changeMap = changeMap;
      vm.cancel = cancel;
      vm.save = save;
      vm.checkMapPermissions = checkMapPermissions;
      vm.maps = [];
      vm.paginatorPlaceholder = $filter('translate')('maps.e11');
      vm.userMapPermissions = [];
      vm.userLogin;
      loadUserMaps();
      initSubscriptions();
    }

    function checkMapPermissions(userLogin) {
      vm.userLogin = userLogin;
      vm.mapsFacade.validateMapPermissions(vm.map.id, userLogin);
    }

    function initSubscriptions() {
      vm.mapsFacade.mapPermissions$.pipe(takeUntil(vm.destroyMaps$)).subscribe((permissions) => {
        const devicesWithoutPermission = permissions.filter((p) => !p.hasPermission);
        if (devicesWithoutPermission) {
          vm.userMapPermissions[vm.userLogin] = devicesWithoutPermission;
        }
      });
    }

    function editMap() {
      vm.isCreateNewMap = false;
      vm.editing = true;
      vm.map.addEventListener('moveend', function (ev) {
        var lat = ev.target.getCenter().lat;
        var lng = ev.target.getCenter().lng;
        vm.map.center = lat + ';' + lng;
      });
      var lat = vm.map.getCenter().lat;
      var lng = vm.map.getCenter().lng;
      vm.map.center = lat + ';' + lng;
      vm.backup = {};
      vm.backup.name = angular.copy(vm.map.name);
      vm.backup.center = angular.copy(vm.map.center);
      vm.backup.id = angular.copy(vm.map.id);
      vm.backup.userId = angular.copy(vm.map.userId);
    }

    function newMap() {
      vm.isCreateNewMap = true;
      vm.editing = true;
      vm.new = true;
      vm.map.on('moveend', centerMap);
      var lat = vm.map.getCenter().lat;
      var lng = vm.map.getCenter().lng;
      vm.map.center = lat + ';' + lng;
      vm.backup = {};
      vm.backup.name = angular.copy(vm.map.name);
      vm.backup.id = angular.copy(vm.map.id);
      vm.backup.center = angular.copy(vm.map.center);

      vm.map.name = '';
      vm.map.id = '';
    }

    function centerMap(ev) {
      var lat = ev.target.getCenter().lat;
      var lng = ev.target.getCenter().lng;
      vm.map.center = lat + ';' + lng;
    }

    function deleteMap() {
      $confirm({ text: $filter('translate')('maps.confirm.delete_map') }).then(() => {
        vm.editing = false;
        vm.areaInfo = false;
        vm.equipoInfo = false;
        mapsFactory.deleteMap(vm.map.id).then(() => {
          vm.mapsFacade.deleteMap();
        });
        var index = _.findIndex(vm.maps, { id: vm.map.id });
        vm.maps.splice(index, 1);
        var length = vm.maps.length;
        if (length !== 0) {
          selectMap(_.first(vm.maps));
        }
      });
    }

    function cancel() {
      if (vm.backup !== undefined) {
        vm.map.center = vm.backup.center;
        vm.map.name = vm.backup.name;
        vm.map.id = vm.backup.id;
      }
      vm.map.off('moveend', centerMap);
      vm.editing = false;
      vm.new = false;
    }
    function selectMap(event) {
      const mapId = _.get(event, 'detail.value') || event.value || event;
      vm.mapsFacade.loadMarkers(mapId.id || mapId);
      map = vm.maps.find((map) => map.id === mapId);
      if (!map) var map = vm.item;
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
      map.active = true;
      var center = map.mapCenter.split(';');

      vm.map.setView([center[0], center[1]], map.mapZoom);
      vm.map.center = map.mapCenter;
      vm.map.name = map.name;
      vm.map.userId = map.userId;
      vm.map.id = map.id;
      vm.map.sharedList = map.sharedList;
      vm.map.shared = map.shared;
      vm.map.sharingLvl = map.sharingLvl;
      vm.reloadMap();

      if ($state.current.parent === 'maps') {
        $state.go('^');
      }
      // var index = _.findIndex(vm.maps, { id: vm.item.id });
      // mapsFactory.setFilterStorage('my-map-storage_' + $rootScope.user.id, index);
    }

    function save(update) {
      var map = {
        mapCenter: vm.map.center,
        mapZoom: vm.map._zoom,
        name: vm.map.name,
      };
      if (update !== null) {
        map.id = vm.map.id;
      }
      vm.map.off('moveend', centerMap);
      mapsFactory.updateMap($rootScope.user.id, map).then((res) => {
        if (res?.plain()) {
          vm.map.id = res.plain().id;
          vm.mapsFacade.addMap(res.plain());
        }
        vm.editing = false;
        vm.new = false;
        loadUserMaps(vm.map.id);
      });
    }

    function loadUserMaps(id) {
      if (!vm.map) {
        return;
      }
      vm.mapsFacade.loadMaps();
      vm.mapsFacade.maps$.pipe(takeUntil(vm.destroyMaps$)).subscribe((list) => {
        vm.maps = maps = list;

        if (_.isEmpty(maps)) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              function (position) {
                vm.map.setView([position.coords.latitude, position.coords.longitude], 14);
                vm.map.center = position.coords.latitude + ';' + position.coords.longitude;
              },

              // if geolocation fails, center map to iberian peninsula
              () => vm.map.setView([40.9327783, -3.3249886], 5)
            );
          } else {
            if (id) {
              selectMap(id);
            }
            vm.map.setView([41.629346, 1.007178], 5);
            vm.map.center = '41.629346;1.007178';
          }
        } else if (id === null || id === undefined) {
          let index = 0;

          vm.item = maps[index];

          vm.mapsFacade.loadMarkers(vm.item.id);

          if (maps[index].mapCenter) {
            const center = maps[index].mapCenter.split(';');
            vm.map.setView([center[0], center[1]], maps[index].mapZoom);
            vm.map.center = maps[index].mapCenter;
          } else {
            vm.map.setView([41.629346, 1.007178], 5);
            vm.map.center = '41.629346;1.007178';
          }
          vm.map.name = maps[index].name;
          vm.map.sharedList = maps[index].sharedList;
          vm.map.shared = maps[index].shared;
          vm.map.sharingLvl = maps[index].sharingLvl;
          vm.map.id = maps[index].id;
          vm.map.userId = maps[index].userId;
        } else {
          /*                var map = _.filter(maps, o => {
            return o.id = id;
          })[0];
          var center = map.mapCenter.split(';');
          vm.map.setView([center[0], center[1]], map.mapZoom);
          vm.map.center =  map.mapCenter;
                vm.map.name =  map.name;
                vm.map.id =  map.id;*/
        }
        vm.carousel_maps = _.take(vm.maps, 3);
        vm.filterUpdated();
      });
    }

    function carouselPrev() {
      var item = vm.carousel_maps[0];
      var index = _.indexOf(vm.maps, item);
      var length = vm.maps.length;
      var new_carousel = [];
      if (index + 4 > length) {
        var loop = index + 4 - length;
        new_carousel = _.concat(_.slice(vm.maps, index + 1, length), _.slice(vm.maps, 0, loop));
      } else {
        new_carousel = _.slice(vm.maps, index + 1, index + 4);
      }
      vm.carousel_maps = new_carousel;
    }
    function carouselNext() {
      var item = _.last(vm.carousel_maps);
      var index = _.indexOf(vm.maps, item);
      var length = vm.maps.length;
      var new_carousel = [];
      if (index - 2 <= 0) {
        var loop = length + (index - 3);
        new_carousel = _.concat(_.slice(vm.maps, loop, length), _.slice(vm.maps, 0, index));
      } else {
        new_carousel = _.slice(vm.maps, index - 3, index);
      }
      vm.carousel_maps = new_carousel;
    }

    function changeMap(event) {
      if (event.detail.value) {
        vm.activeMap = event.detail.value;
        //changeState({ map: vm.mapSelected, id: vm.mapSelected.pk.id }, $state.params.config);
      }
    }
    $scope.$on('$destroy', function () {
      vm.destroyMaps$.next();
      vm.destroyMaps$.complete();
      vm.mapsFacade.clearMarkerResponse();
      vm.mapsFacade.clearMapResponse();
    });
  }
})();
