import { UNITS } from '../utils/units.enum';
import { showLoader, hideLoader } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sensorsConfigASController', sensorsConfigASController);

  sensorsConfigASController.$inject = [
    '$scope',
    '$state',
    'sensorsFactory',
    'autoFormatFactory',
    '$confirm',
    '$filter',
  ];

  function sensorsConfigASController($scope, $state, sensorsFactory, autoFormatFactory, $confirm, $filter) {
    var vm = this;
    var saveFormat;
    vm.activeList;
    vm.lastSensor;
    vm.nextSensor;
    vm.selectSensor;
    vm.sensorAnalogs;
    vm.analogs = [];
    vm.index = 0;
    vm.page = 1;
    activate();

    function activate() {
      vm.overlayEl = document.querySelector('#sensor-config-overlay');
      vm.overlayEl.show();
      vm.currentState = $state.includes;
      vm.UNITS = UNITS;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.formats = vm.currentUnit.formats;
        autoFormatFactory.setFormats(vm.formats);

        loadAnalogs();
        vm.UNITS = UNITS;
        vm.selectSensor = selectAnalog;
        vm.nextSensor = nextSensor;
        vm.lastSensor = lastSensor;
        vm.cancel = cancel_edition;
        vm.getFormatExample = getFormatExample;
        vm.selectFormat = selectFormat;
        vm.save = save;
        vm.setColor = setColor;
        vm.parsed;
        vm.captionRange = _.range(0, 5);
        vm.changeFormat = changeFormat;
        vm.changeState = changeState;
        vm.changeSensor = changeSensor;
        vm.changingSensor = changingSensor;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.addFormatId = addFormatId;
        vm.setMinimumsAndMaximums = setMinimumsAndMaximums;
        vm.setAutomaticFormat = setAutomaticFormat;
        if (!vm.sensor.backup) {
          backup();
        }
      } else {
        $state.go('units');
      }
    }

    function setAutomaticFormat() {
      if (vm.sensor.input === 17) {
        vm.sensor.formatId = 39;
      } else if (vm.sensor.input === 18) {
        vm.sensor.formatId = 38;
      }
    }

    $scope.$watch(
      () => vm.sensor.input,
      () => {
        setAutomaticFormat();
      }
    );
    /*     function loadAnalogs(n){
        vm.analogs = [];
        var params = {'page':n, limit:1}
        vm.loading = true;
        vm.sensor = {};

        if(vm.currentUnit.type != 7){
            return sensorsFactory.analogsPage(vm.currentUnit.id,params).then(function(data){
                vm.paginator = data;
                vm.currentUnit.analogs = vm.paginator.content;
                angular.copy(vm.paginator.content[0],vm.sensor);
                vm.totals = _.range(1,vm.paginator.totalPages + 1);
                vm.page = vm.paginator.number + 1;
                if(vm.currentUnit.type != 5)selectFormat();
                vm.loading = false;
            });
        } else {
            sensorsFactory.formatsAll(vm.currentUnit.id).then( formatData => {
                vm.currentUnit.formatList = formatData;
            })

            sensorsFactory.analogsAll(vm.currentUnit.id).then(data => {
                vm.currentUnit.analogs = data;

                changeState(vm.page);
                addFormatId();

            }).catch(e => {
                throw e;
            })
        }
    } */

    function loadAnalogs() {
      vm.loading = true;
      vm.sensor = {};
      showLoader();

      if (vm.currentUnit.type === UNITS.A_4500) {
        sensorsFactory.formatsAll(vm.currentUnit.id).then((formatData) => {
          vm.formats = formatData.plain();
          hideLoader();
        });
      }

      sensorsFactory.analogsAll(vm.currentUnit.id).then(function (data) {
        vm.analogs = data.plain();
        vm.sensorAnalogs = vm.analogs[0].pk.id;
        vm.totals = vm.analogs.length;
        angular.copy(vm.analogs[0], vm.sensor);
        hideLoader();
        if (vm.currentUnit.type === UNITS.A_4500) {
          //changeState(vm.sensorAnalogs);
          //addFormatId();
          //vm.sAnalogsDTO = veggaSelectFactory.formatterVeggaSelect(vm.currentUnit.digitals, '{{pk.id}}', '{{pk.id}} - {{name}}', 'number');
        }
        if (vm.currentUnit.type !== UNITS.A_7000) selectFormat();
        vm.loading = false;
      });
    }

    function addFormatId() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.format.forEach((f) => {
          f.formatId = vm.currentUnit.analogs[parseInt(f.id) - 1].formatId;
        });
      }
      return vm.format;
    }

    function changeState(params) {
      vm.active = null;
      vm.sensor = vm.currentUnit.analogs[params - 1];
      backup();
    }

    /*     function changeSensor(e) {
        console.log(e)
        if (e.detail.value) {
            vm.sensor = e.detail.option
            vm.page = Number(e.detail.value)
        }
    } */

    function changingSensor($event) {
      if (vm.form_a && vm.form_a.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('sensors.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          if (vm.form45 && vm.form45.$dirty) {
            vm.form45.$setPristine(); //Actualitzem estat del formulari a inicial
          } else {
            vm.form_a.$setPristine(); //Actualitzem estat del formulari a inicial
          }

          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    /* function selectAnalog(){
        vm.index = parseInt(vm.sensorAnalogs)-1;
        angular.copy(vm.currentUnit.analogs[vm.index],vm.sensor);
        selectFormat();
    } */

    function selectAnalog() {
      vm.index = parseInt(vm.sensorAnalogs) - 1;
      angular.copy(vm.analogs[vm.index], vm.sensor);
      selectFormat();
    }

    function selectFormat(flag = false) {
      if (vm.sensor.formatId > 0) {
        vm.format = vm.formats[vm.sensor.formatId - 1];
        if (vm.currentUnit.type === UNITS.A_4500 && vm.format) {
          setMinimumsAndMaximums(flag);
        }
      } else {
        vm.format = { decimals: 0, integers: 0, units: '-' };
      }
    }

    function setMinimumsAndMaximums(flag) {
      let minimumAndMaximum = getMinimumAndMaximum();
      let minimum = minimumAndMaximum[0];
      let maximum = minimumAndMaximum[1];
      vm.minimumsMaximums = {
        min: minimum,
        max: maximum,
      };
      // set everything to 0 since mask-sensor-unit doesn't watch minimum and maximum scope only when update format
      if (flag) {
        vm.sensor.tara = 0;
        vm.sensor.minGraphic = 0;
        vm.sensor.maxGraphic = 0;
      }
    }

    function getMinimumAndMaximum() {
      let totalDigits = vm.format.decimals + vm.format.integers;
      let minimum = 0;
      let maximum = '';
      for (let i = 0; i < totalDigits; i++) {
        maximum += '9';
      }

      maximum = Number((maximum / 10 ** vm.format.decimals).toFixed(vm.format.decimals)); // using toFixed() to avoid Float problems
      if (vm.format.format != null && vm.format.format.includes('±')) {
        minimum = -maximum;
      }
      return [minimum, maximum];
    }

    /*     function lastSensor(){
        --vm.page;
        if(vm.page === 0){
            vm.page = vm.paginator.totalPages;
        }
        loadAnalogs(vm.page);

    }

    function nextSensor(){
        ++vm.page;
        if(vm.page > vm.paginator.totalPages){
            vm.page = 1;
        }
        loadAnalogs(vm.page);

    } */

    function lastSensor() {
      --vm.index;
      if (vm.index === -1) {
        vm.index = vm.totals - 1;
      }
      vm.sensorAnalogs = (vm.index + 1).toString();
      angular.copy(vm.analogs[vm.index], vm.sensor);
      selectFormat();
    }

    function nextSensor() {
      ++vm.index;
      if (vm.index > vm.totals - 1) {
        vm.index = 0;
      }
      vm.sensorAnalogs = (vm.index + 1).toString();
      angular.copy(vm.analogs[vm.index], vm.sensor);
      selectFormat();
    }

    function changeSensor(e) {
      if (e.detail.value) {
        vm.index = +e.detail.value;
        vm.sensorAnalogs = vm.index.toString();
        angular.copy(vm.analogs[vm.index], vm.sensor);
        selectAnalog();
        selectFormat();
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSensor($event);
          break;
      }
    }

    function getFormatExample(format) {
      var example = '';
      if (format.sign) {
        example = example + '\u00B1 ';
      }

      _.times(format.integers, () => {
        example = example + '0';
      });
      if (format.decimals > 0) {
        example = example + '.';
        _.times(format.decimals, () => {
          example = example + '0';
        });
      }
      example = example + ' ' + format.units;
      return example;
    }

    function setColor() {
      vm.pickerActive = false;
      switch (vm.sensor.caption.numLvl) {
        case 3:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = vm.sensor.color.hex;
          vm.sensor.caption.color3 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
        case 5:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 30);
          vm.sensor.caption.color3 = vm.sensor.color.hex;
          vm.sensor.caption.color4 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -30);
          vm.sensor.caption.color5 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
      }
    }

    function changeFormat() {
      switch (vm.sensor.typeInitSensor) {
        case 0:
          vm.sensor.formatId = 8;
          break;
        case 1:
          vm.sensor.formatId = 9;
          break;
        case 2:
          vm.sensor.formatId = 4;
          break;
        case 3:
          vm.sensor.formatId = 5;
          break;
        case 4:
          vm.sensor.formatId = 10;
          break;

        default:
          break;
      }
    }

    function save() {
      vm.loading = true;
      vm.sensor.unittype = vm.currentUnit.type;
      vm.form_a.$setPristine();
      if (saveFormat) {
        _.forEach(vm.formats, (o) => {
          o.unittype = vm.currentUnit.type;
        });
        sensorsFactory.updateFormats(vm.currentUnit.id, vm.formats).then(() => {
          //$state.go('sensors.detail',{unit:vm.currentUnit});
          $scope.$emit('refresh-format', { unit: vm.currentUnit });
          $scope.$emit('refresh-sensors');
        });
      }

      sensorsFactory.updateAnalogs(vm.currentUnit.id, vm.sensor).then((response) => {
        vm.sensor = response.plain();
        vm.analogs[response.pk.id - 1] = response.plain();
        if (vm.currentUnit.type !== vm.UNITS.A_7000) selectFormat();
        vm.loading = false;
        $scope.$emit('refresh-sensors');
      });
    }

    function backup() {
      vm.sensors_backup = {};
      angular.copy(vm.sensor, vm.sensors_backup);
      vm.sensor.backup = true;
    }

    function cancel_edition() {
      if (vm.currentUnit.type == UNITS.A_4500) {
        angular.copy(vm.sensors_backup, vm.sensor);
      } else {
        $state.go('sensors.detail', { unit: vm.currentUnit });
      }
    }
    $scope.$watch('vm.parsed', () => {
      autoFormatFactory.parse(vm.parsed).then((f) => {
        if (f && Object.keys(f).length > 0) {
          if (f.id !== null) {
            vm.format = vm.formats[f.id - 1];
            vm.sensor.formatId = f.id;
          } else if (f.format !== null) {
            let id = f.format.pk.id;
            vm.formats[id - 1] = f.format;
            vm.format = f.format;
            vm.currentUnit.formats = vm.formats;
            vm.sensor.formatId = id;
            saveFormat = true;
          }
        }
      });
    });
    $scope.$on('refresh', function () {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form_a,
      () => {
        $scope.$emit('formUpdated', vm.form_a);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e) => {
      save(e, vm.form_a);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
